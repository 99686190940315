<template>
    <div :class="[show_help_list ? 'width-50' : 'width-auto']" class="help-wrap">
        <div v-if="show_help_list" class="help-list-wrap custom_scrollbar-1">
            <div class="is-flex is-justify-content-space-between has-text-white mb-2 is-align-items-center">
                {{$t('help')}}
                <a v-if="am_allowed('help.can_create')" @click.prevent="toggle_new_help()" href="#" class="is-pulled-right button is-rounded px-3 py-2 is-success">
                    <i class="fas fa-plus"></i>
                </a>
            </div>
            <!-- Create new help -->
            <div v-if="show_create_help">
                <div class="field">
                    <label class="label has-text-white">{{$t('create_help')}} </label>
                    <div class="control">
                        <input v-model="new_help.title" class="input is-small" type="text" :placeholder="$t('help_title')">
                    </div>
                </div>
                <div class="field mb-1">
                    <div class="control">
                        <vue-editor v-model="new_help.content"
                            :editorToolbar="customToolbar"
                            :placeholder="$t('help_content')"
                            id="edit-editor" class="help-description has-background-white"></vue-editor>
                        <!-- <textarea class="textarea is-small" placeholder="Textarea"></textarea> -->
                    </div>
                </div>
                <div class="is-flex">
                    <a @click.prevent="save_help(new_help)" class="button is-small is-success ml-auto is-pulled-right mb-3">{{$t('save')}}</a>
                </div>
            </div>

            <!-- Help list -->
            <div v-if="help_list.length > 0" class="">
            <draggable v-model="help_list_1"  :draggable="canDragRow">
                <div v-for="(help, index) in help_list_1" :key="index" class="single-help help-row mb-2" 
                    :class="[help.collapsed ? 'has-background-white' : 'has-background-white-ter']">
                    <div @click.self="toggle_help(help)" class="px-2 py-1 help-title is-flex is-justify-content-space-between is-clickable" >
                        <div style="width: 90%">
							<div v-if="!help.edit_mode" @click.self="toggle_help(help)" v-html="help.title">
                            </div>
                            <div v-else>
                                <input v-model="help.title" class="input is-small my-1" type="text" placeholder="Text input">
                            </div>
                        </div>
                        <div>
                            <a v-if="am_allowed('help.can_edit')" @click.prevent="toggle_help_edit(help)" href="#" class="is-align-self-flex-end">
                                <i class="fas fa-edit"></i>
                            </a>
                            <a v-if="am_allowed('help.can_delete_ohers')" @click.prevent="delete_help(help)" href="" class="has-text-danger ml-2">
                                <i class="far fa-trash-alt"></i>
                            </a>
                        </div>
                    </div>
                    <div v-if="help.collapsed" class="px-2">
                        <div v-if="help.edit_mode">
                            <vue-editor v-model="help.content" :editorToolbar="customToolbar" id="edit-editor" class="help-description has-background-white "></vue-editor>
                        </div>
                        <div v-else class="help-content" v-html="help.content"></div>
                        <div class="is-flex is-justify-content-flex-end">
                            <a v-if="help.edit_mode" @click.prevent="edit_help(help)" href="" class="button is-info is-small my-2 is-pulled-">
                                {{$t('save')}}
                            </a>
                        </div>
                    </div>
                </div>
            </draggable>
            <a v-if="sortHasChanged" @click.prevent="save_sort()" href="" class="button is-success is-small">{{$t('save_sort')}}</a>
            </div>

        </div>
        <a @click.prevent="open_help()" href="#" class="is-pulled-right is-info button help-btn mt-3 p-3">
            <i class="far fa-question-circle"></i>
        </a>
    </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from "vue2-editor";
import draggable from 'vuedraggable'
import permissionMixin from '@/permissions-mixin.js'

export default {
    mixins: [permissionMixin],
    components: {
        VueEditor,
        draggable,
    },
    props:['app_lang', 'app_route'],
    data() {
        return {
            new_help: {}, 
            show_help_list: false,
            show_create_help: false,
            help_list: [],
            drag: false,
            canDragRow: "",
            originalPageHelpListIds: [],
            help_list_1: [],
            customToolbar: [ 
                [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                ['blockquote'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'color': [] }, { 'background': [] }],
                ['link'],
                // ['table'],
                ['image'],
            ]
        }
    },
    created(){
        this.get_help() // get all
        this.canDragRow = '.help-row'
    },
    methods: {
        open_help(){
            this.show_help_list = !this.show_help_list
        },
        toggle_new_help(){
            this.show_create_help = !this.show_create_help
        },
        save_help(h){
            var language_keys = Object.keys(this.$i18n.messages);
            axios.post(this.$tetris_server + '/save/help', {
                data: h,
                route: this.$parent.get_url(),
                created_by: JSON.parse(localStorage.getItem("user")).IdUser,
                lang: this.$i18n.locale,
                project_id: this.$parent.$parent.currentEvent, 
                language_keys: language_keys
            }).then(res => {
                if(res.data[0]){
                    this.help_list.unshift(res.data[0])
                    this.help_list_1 = this.help_list.filter(h => {
                        return (h.route == this.app_route && h.lang == this.app_lang)
                    })
                    this.originalPageHelpListIds = this.help_list_1.map(e => {
                        return e.id
                    })
                    this.$toast(this.$t("help_created_successfully"), "success")
                }
                // Response after create or edit
                this.new_help = { }
                this.show_create_help = false
            })
        },
        get_help(){
            axios.post(this.$tetris_server + '/get/help', {
                route: this.$parent.get_url(),
                project_id: this.$parent.$parent.currentEvent
            }).then(res => {
                this.help_list = [...res.data.map(h =>{
                    h.collapsed = false
                    h.edit_mode = false
                    return h
                })]
                this.help_list_1 = this.help_list.filter(h => {
                    return (h.route == this.app_route && h.lang == this.app_lang)
                }).sort(function(a, b){
                    return (a.display_order > b.display_order) ? 1 : -1;
                });
                this.originalPageHelpListIds = this.help_list_1.map(e => {
                    return e.id
                })
            })
        },
        toggle_help(help){
            this.help_list = [...this.help_list.map(h => {
                if(h.guid == help.guid){
                    h.collapsed = !h.collapsed
                    h.edit_mode = false
                }
                return h
            })]
        },
        edit_help(help){
            axios.post(this.$tetris_server + '/edits/help', {
                help: help
            }).then(() => {
                this.toggle_help_edit(help)
                // help.edit_mode = false
                // help.collapsed = false
                this.$toast(this.$t("help_edited_successfully"), "success")
                
            })
        },
        toggle_help_edit(help){
            this.help_list = [...this.help_list.map(h => {
                if(h.guid == help.guid){
                    h.edit_mode = !h.edit_mode
                    h.collapsed = true
                }
                return h
            })]
        },
        delete_help(help){
            this.$fire({
                text: this.$t("are_you_sure_delete_help"),
                showCancelButton: true,
                confirmButtonText: this.$t("yes"),
                cancelButtonText: this.$t("no"),
                width: 300,
                customClass: {
                    confirmButton: 'button is-success is-small',
                    cancelButton: 'button is-small',
                },
            }).then(response => {
                if(response.value){
                    axios.post(this.$tetris_server + '/delete/help', { help: help })
                    .then(r => {
                        if(r.data == 1){
                            this.help_list = [...this.help_list.filter(h => h.guid != help.guid)]
                            this.help_list_1 = [...this.help_list_1.filter(h => h.guid != help.guid)]
                            this.originalPageHelpListIds = this.help_list_1.map(e => {
                                return e.id
                            })
                            this.$toast(this.$t("help_deleted_successfully"), "success")
                        }
                    })
                }
            })
        },
        save_sort(){
            axios.post(this.$tetris_server + '/save/sort/help', {data: this.help_list_1})
            .then(() => {
                this.originalPageHelpListIds = this.help_list_1.map(h => {
                    return h.id
                })
                // this.help_list = [...this.help_list.map(he => {
                //     res.data.map(rr => {
                //         if(he.guid == rr.guid)
                //             he.display_order = rr.display_order
                //     })
                //     return he
                // })]

                this.$toast(this.$t("sort_updated_successfully"), "success")
            })
        },
        change_log(){
            ChannelSplitterNode.log
        }
    },
    computed: {
        help_list_filtered: {
            get() {
                return this.help_list.filter(h => {
                    return (h.route == this.$parent.get_url() && h.lang == this.$i18n.locale)
                })
            },
            set(value) {
                this.help_list_1 = [...this.help_list.map(e => {
                    value.map((v, index) => {
                        if(v.guid == e.guid){ 
                            e = {...v, display_order: index}
                        }
                    })
                    return e
                })]
            }
        },
        sortHasChanged(){
            var pageHelpIds = this.help_list_1.map(h => {
                return h.id
            })
            return JSON.stringify(pageHelpIds) != JSON.stringify(this.originalPageHelpListIds)
        },
    },
    watch: {
        app_lang: function(){
            this.help_list_1 = [...this.help_list.filter(h => {
                return (h.route == this.$parent.app_route && h.lang == this.$parent.app_lang)
            })]
            this.originalPageHelpListIds = this.help_list_1.map(e => {
                return e.id
            })
        },
        app_route: function(){
            this.help_list_1 = [...this.help_list.filter(h => {
                return (h.route == this.$parent.app_route && h.lang == this.$parent.app_lang)
            })]
            this.originalPageHelpListIds = this.help_list_1.map(e => {
                return e.id
            })
        },
    }
}
</script>

<style>
    .help-wrap{
        position:fixed;
        right:10px;
        bottom:10px;
        z-index: 999;
    }
    .help-list-wrap{
        background: #000000d1;
        padding:10px;
        /* border-top: solid 10 px rgba(0, 0, 0, 0); */
        /* border-bottom: solid 10 px rgba(0, 0, 0, 0); */
        height:60vh;
        overflow-y:scroll;
        box-shadow: 0 0 10px 5px #6c6d6fe3;

        /* color:#fff; */
    }
    .help-btn{
        border-radius:50%;
		background: #6379a2 !important;
    }
    #edit-editor, #create-editor{
        height:350px;
        border: 1px solid #282828;
        /* background: #fff; */
    }
    .help-description .ql-toolbar {
        /* background:#fff!important; */
        border: 1px solid #171717!important;
        border-bottom-width: 0px;
    }
    .help-title{
        /* border-bottom:1px solid #000; */
    }
    .single-help{
		color: #51749d;
        border-bottom:solid 1px #000;
    }
    .sortable-drag{
        opacity: 0;
    }
    .width-auto{
        width:auto;
    }
    .width-50{
        width:50%;
    }
	.help-content > ul, ol {
  		list-style-type: circle;
  		list-style-position: inside;
		margin: auto !important;
		padding: 15px !important;
	}
	.help-content > ul {
  		list-style-type: disc;
  		list-style-position: inside;
	}
	.help-content > ol {
  		list-style-type: auto;
  		list-style-position: inside;
	}
    
</style>
