// import socket from '@/pages/user-roles.js'
var CryptoJS = require("crypto-js");
export default {
    data() {
        return {
            test_data: 'test data',
            permissions_modules: [
                {
                    module_name: "request", id: 0,
                    permissions: [
                        {name: 'can_read', description: this.$t('description_request_can_read'), value: 0, original_value: 0},
                        {name: 'can_edit', description: this.$t('description_request_can_edit'), value: 0, original_value: 0},
                        {name: 'can_delete', description: this.$t('description_request_can_delete'), value: 0, original_value: 0},
                        {name: 'can_finalize_request', description: this.$t('description_request_can_finalize_request'), value: 0, original_value: 0}
                    ] 
                },
                { 
                    module_name: "warehouse",  id: 0,
                    permissions: [
                        {name: 'can_read', description: this.$t('description_warehouse_can_read'), value: 0, original_value: 0},
                        {name: 'can_edit', description: this.$t('description_warehouse_can_edit'), value: 0, original_value: 0},
                        {name: 'can_delete', description: this.$t('description_warehouse_can_delete'), value: 0, original_value: 0},
                        {name: 'can_create_job', description: this.$t('description_warehouse_can_create_job'), value: 0, original_value: 0},
                        {name: 'can_confirm_job', description: this.$t('description_warehouse_can_confirm_job'), value: 0, original_value: 0},
                        
                    ]
                },
                { 
                    module_name: "parameter",  id: 0,
                    permissions: [
                        {name: 'can_read', description: this.$t('description_parameter_can_read'), value: 0, original_value: 0},
                        {name: 'can_create', description: this.$t('description_parameter_can_create'), value: 0, original_value: 0},
                        {name: 'can_edit', description: this.$t('description_parameter_can_edit'), value: 0, original_value: 0},
                        {name: 'can_delete', description: this.$t('description_parameter_can_delete'), value: 0, original_value: 0},
                    ]
                },
                { 
                    module_name: "planning",  id: 0,
                    permissions: [
                        {name: 'can_read', description: this.$t('description_planning_can_read'), value: 0, original_value: 0},
                    ]
                },
                {
                   module_name: "help",  id: 0,
                    permissions: [
                        {name: 'can_create', description: this.$t('help_can_create_description'),value: 0, original_value: 0},
                        {name: 'can_edit', description: this.$t('help_can_edit_description'),value: 0, original_value: 0},
                        {name: 'can_delete_ohers', description: this.$t('help_can_delete_ohers_description'),value: 0, original_value: 0},
                    ]
                },
                {
                    module_name: "user_roles",  id: 0,
                    permissions: [
                        {name: 'can_create', description: this.$t('user_roles_can_create_description'), value: 0, original_value: 0},
                        {name: 'can_edit', description: this.$t('user_roles_can_edit_description'), value: 0, original_value: 0},
                        // {name: 'can_disable', description: this.$t('user_roles_can_disable_description'), value: 0, original_value: 0},
                        
                        {name: 'can_create_role', description: this.$t('user_roles_can_create_role_description'), value: 0, original_value: 0},
                        {name: 'can_edit_role', description: this.$t('user_roles_can_edit_role_description'), value: 0, original_value: 0},
                        {name: 'can_edit_permissions', description: this.$t('user_roles_can_edit_permissions_description'), value: 0, original_value: 0},
                    ]
                },
                // {
                //     module_name: "role_permissions",  id: 0,
                //     permissions: [
                        
                //         {name: 'can_create', description: this.$t('role_permissions_can_create_description'), value: 0, original_value: 0},
                //         {name: 'can_edit', description: this.$t('role_permissions_can_edit_description'), value: 0, original_value: 0},
                //         {name: 'can_assign', description: this.$t('role_permissions_can_assign_description'), value: 1, original_value: 0},
                //     ]
                // }
            ],
            user_role_permissions_modules: []
        }
    },
    methods: {
        am_allowed(module){
            // Decrypt localStorage.permissions
            var user = JSON.parse(localStorage.tetris_user)
            var bytes  = CryptoJS.AES.decrypt(localStorage.permissions, localStorage.token);
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            var user_permissions = JSON.parse(originalText)
            let user_role_permissions_modules = this.permissions_merge(user_permissions, 0, user.type_id)
            var module_parts = module.split('.')
            var is_allowed = false
            if(user_role_permissions_modules.length > 0)
                user_role_permissions_modules.map(m => {
                    if(m.module_name == module_parts[0]){
                        m.permissions.map(p => {
                            if(p.name == module_parts[1]) is_allowed = p.value == 1
                            return p
                        })
                        return m
                    }
                })
            return is_allowed
        },
        permissions_merge(permissions_list, change, is_admin = 0){
            let user_role_permissions_modules = [] 
            // console.log('...', permissions_list)
            
            // if(permissions_list.length > 0)
                this.permissions_modules.map(pm => {
                    let module_found = false
                    if(is_admin != -1)
                    permissions_list.map(pl => {
                        if(pl.name == pm.module_name){
                            module_found = true
                            let permermission_details = {
                                id: pl.id,
                                module_name: pm.module_name,
                                permissions: pm.permissions.map((p, index) => {
                                    return {
                                        name: p.name,
                                        description: p.description,
                                        value: parseInt(pl.permissions_values[index]),
                                        original_value: parseInt(pl.permissions_values[index])
                                    }
                                })
                            }
                            user_role_permissions_modules.push(permermission_details)
                        }
                    })
                    if(!module_found) {
                        let permis = {...pm}
                        if(is_admin == -1) {
                            permis.permissions = [...permis.permissions.map(mp => {
                                mp.value = 1
                                mp.original_value = mp.value
                                return mp
                            })]
                        }
                        user_role_permissions_modules.push({...permis})
                    }
                })
            // console.log('user_role_permissions_modules', user_role_permissions_modules)
            // if(user_role_permissions_modules.length == 0) this.user_role_permissions_modules = [...this.permissions_modules]

            if(change == 1) this.user_role_permissions_modules = [...user_role_permissions_modules]
            else return user_role_permissions_modules
            // BUG: if change == 0
        },
        
    },
    computed: {
        // your permissions ...
    },
}